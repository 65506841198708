import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {string as tString, number as tNumber, arrayOf as tArrayOf} from 'prop-types';
import {Button} from "../index";
import {fetchVariations} from "../../redux/asyncActions/pizzas";

const SinglePizza = ({name, id, images, price, attributes, onAddToCart, addedPizzas, variations, dispatch, varLoading}) => {
    const imageUrl = images[0].src
    const types = attributes[0].options
    const sizes = attributes[1].options
    const [curPrice, setCurPrice] = useState(price)
    const [curVarID, setCurVarID] = useState(null)
    const [waitVar, setWaitVar] = useState(false);
    const typeName = ['thin', 'classic']
    const [activeType, setActiveType] = useState(typeName.find(el => el === types[0]))
    const onSelectType = (index) => {
        setActiveType(index);
    }
    const pizzaSizes = ['10', '12', '16']
    const [activeSize, setActiveSize] = useState(pizzaSizes.find(el => el === sizes[0]));
    const onSelectSize = useCallback((index) => {
        if (!variations) {
            dispatch(fetchVariations(id))
        }
        setActiveSize(index)
    }, [dispatch, id, variations])
    useEffect(() => {
        if(variations) {
            const curVar = variations?.find(el => el.attributes[0].option === activeSize)
            setCurPrice(curVar.price)
            setCurVarID(curVar.id)
            if (waitVar) {
                createObj()
            }
        }
    }, [variations, activeSize])

    const option = (arrName, arrValue, func, active, inch = '') => {
        return arrName.map(name => (
            <li key={name}
                onClick={() => func(name)}
                className={classNames({
                    'active': active === name,
                    'disable': !arrValue.includes(name)
                })}>{name}{inch}</li>
        ))
    }

    const createObj = () => {
       if (!curVarID) {
           dispatch(fetchVariations(id))
           setWaitVar(true)
       } else {
           setWaitVar(false)
           const obj = {
               name,
               id,
               variation_id: curVarID,
               imageUrl,
               price: +curPrice,
               type: activeType,
               size: activeSize
           }
           onAddToCart(obj)
       }
    }
    return (
        <div className="pizza-block">
            <img
                className="pizza-block__image"
                src={imageUrl}
                alt={name}
            />
            <h4 className="pizza-block__title">{name}</h4>
            <div className="pizza-block__selector">
                <ul>
                    {option(typeName, types, onSelectType, activeType)}
                </ul>
                <ul>
                    {option(pizzaSizes, sizes, onSelectSize, activeSize, ' Inch')}
                </ul>
            </div>
            <div className="pizza-block__bottom">
                <div className="pizza-block__price">${curPrice}</div>
                <Button onClick={createObj} className="button--add" outline disable={varLoading}>
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.8 4.8H7.2V1.2C7.2 0.5373 6.6627 0 6 0C5.3373 0 4.8 0.5373 4.8 1.2V4.8H1.2C0.5373 4.8 0 5.3373 0 6C0 6.6627 0.5373 7.2 1.2 7.2H4.8V10.8C4.8 11.4627 5.3373 12 6 12C6.6627 12 7.2 11.4627 7.2 10.8V7.2H10.8C11.4627 7.2 12 6.6627 12 6C12 5.3373 11.4627 4.8 10.8 4.8Z"
                            fill="white"
                        />
                    </svg>
                    <span>Add</span>
                    {addedPizzas && <i>{addedPizzas}</i>}
                </Button>
            </div>
        </div>
    );
};

SinglePizza.propTypes = {
    name: tString,
    id: tNumber,
    images: tArrayOf(Object),
    price: tString,
    rating: tString,
    attributes: tArrayOf(Object)
}


export default SinglePizza;
