import React from 'react';
import {Button, Col, Form, Input, message, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {clearCart} from "../redux/actions/cart";

const Checkout = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [form] = Form.useForm();
	const cart = useSelector(({cart}) => cart.items)
	const line_items = []
	for (let el in cart) {
		const obj = {
			product_id: cart[el][0].id,
			variation_id: cart[el][0].variation_id,
			quantity: cart[el].length
		}
		line_items.push(obj)
	}
	const onFinish = (values) => {
		const data = {
			payment_method: "cod",
			payment_method_title: "Cash on delivery",
			set_paid: true,
			billing: {
				...values
			},
			shipping: {
				...values
			},
			line_items
		}
		axios.post('https://admin.buldov.com/wp-json/wc/v3/orders', {
			headers: {
				'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYWRtaW4uYnVsZG92LmNvbSIsImlhdCI6MTY0MTA4NTAyMywibmJmIjoxNjQxMDg1MDIzLCJleHAiOjE2NDE2ODk4MjMsImRhdGEiOnsidXNlciI6eyJpZCI6IjEifX19.a_JMzFyGu-NI5e1GNi8u6JTNSdnKR0zX7RN16Nto3KE`
			},
			data
		}).then(res => {
			console.log(res)
		})
		form.resetFields()
		dispatch(clearCart());
		message.success('Thank you for order!')
		history.push('/')
	}
	return (
		<div className="container container-checkout">
			<Form
				name="checkout"
				form={form}
				onFinish={onFinish}
				layout="vertical"
				size="large"
			>
				<Row gutter="16">
					<Col span="12">
						<Form.Item
							label="First name"
							name="first_name"
							rules={[{required: true}]}
						>
							<Input name="first_name" />
						</Form.Item>
					</Col>
					<Col span="12">
					<Form.Item
						label="Last name"
						name="last_name"
						rules={[{required: true}]}
					>
						<Input name="first_name" />
					</Form.Item>
					</Col>
					<Col span="12">
					<Form.Item
						label="Address"
						name="address_1"
						rules={[{required: true}]}
					>
						<Input name="address_1" />
					</Form.Item>
					</Col>
					<Col span="12">
					<Form.Item
						label="Unit"
						name="address_2"
						rules={[{required: true}]}
					>
						<Input name="address_2" />
					</Form.Item>
					</Col>
					<Col span="8">
					<Form.Item
						label="City"
						name="city"
						rules={[{required: true}]}
					>
						<Input name="city" />
					</Form.Item>
					</Col>
					<Col span="8">
					<Form.Item
						label="State"
						name="state"
						rules={[{required: true}]}
					>
						<Input name="state" />
					</Form.Item>
					</Col>
					<Col span="8">
					<Form.Item
						label="Postcode"
						name="postcode"
						rules={[{required: true}]}
					>
						<Input name="postcode" />
					</Form.Item>
					</Col>
					<Col span="12">
					<Form.Item
						label="Email"
						name="email"
						rules={[{required: true}]}
					>
						<Input name="email" />
					</Form.Item>
					</Col>
					<Col span="12">
					<Form.Item
						label="Phone"
						name="phone"
						rules={[{required: true}]}
					>
						<Input name="phone" />
					</Form.Item>
					</Col>
					<Form.Item
					>
						<Button className="checkout-button" style={{backgroundColor: "#fe5f1e"}} htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Row>
			</Form>
		</div>
	);
};

export default Checkout;
