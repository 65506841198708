import classNames from "classnames";

import React from 'react';

const Button = ({onClick, className, outline, children, disable}) => {
    return (
        <button
            onClick={onClick}
            disabled={disable}
            className={classNames('button', className, {
                'button--outline': outline,
                'button--disabled': disable
            })}>
            {children}
        </button>
    );
};

export default Button;
