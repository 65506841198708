export const setLoaded = payload => ({
    type: 'SET_LOADED',
    payload
})

export const setVarLoaded = (id, bool) => ({
    type: 'SET_VAR_LOADED',
    payload: {id, bool}
})

export const setPizzas = (items) => ({
    type: 'SET_PIZZAS',
    payload: items
})

export const setCategories = (items) => ({
    type: 'SET_CATEGORIES',
    payload: items
})

export const setVariations = (items) => ({
    type: 'SET_VARIATIONS',
    payload: items
})


