const initialState = {
    items: [],
    categories: [],
    isLoading: false
}

const pizzas = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PIZZAS':
            return {
                ...state,
                items: action.payload,
                isLoading: true
            }
        case 'SET_CATEGORIES':
            return {
                ...state,
                categories: action.payload,
                isLoading: true
            }
        case 'SET_VARIATIONS':
            return {
                ...state,
                items: state.items.map(el => el.id === action.payload.id ? {...el, variations: action.payload.data, varLoading: false} : el)
            }
        case 'SET_VAR_LOADED':
            return {
                ...state,
                items: state.items.map(el => el.id === action.payload.id ? {...el, varLoading: action.payload.bool} : el)
            }
        case 'SET_LOADED':
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state
    }
}

export default pizzas;