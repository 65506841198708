import axios from "axios";
import {setCategories, setLoaded, setPizzas, setVariations, setVarLoaded} from "../actions/pizzas";

export const fetchPizzas = (category, sortBy) => dispatch => {
	dispatch(setLoaded(false));
	axios.get('https://admin.buldov.com/wp-json/wc/v3/products', {
		headers: {
			'Content-Type': 'application/json'
		},
		params: {
			category: category,
			orderby: sortBy,
			order: 'asc',
			_fields: 'attributes,average_rating,categories,id,images,name,price'
		}
	}).then(({data}) => {
		dispatch(setPizzas(data));
	})
}

export const fetchCategories = () => dispatch => {
	dispatch(setLoaded(false))
	axios.get('https://admin.buldov.com/wp-json/wc/v3/products/categories', {
		headers: {
			'Content-Type': 'application/json'
		},
		params: {
			_fields: 'id,name'
		}
	}).then(({data}) => {
		dispatch(setCategories(data));
	})
}

export const fetchVariations = (id) => dispatch => {
	dispatch(setVarLoaded(id,true))
	axios.get(`https://admin.buldov.com/wp-json/wc/v3/products/${id}/variations`, {
		headers: {
			'Content-Type': 'application/json'
		},
		params: {
			_fields: 'attributes,id,price'
		}
	}).then(({data}) => {
		dispatch(setVariations({data, id}));
	})
}